a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:after,
blockquote:before,
q:after,
q:before {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
body {
  margin-right: 10px;
  margin-left: 10px;
  font-size: 14px;
  line-height: 1.4;
}
em {
  font-style: italic;
}
body,
h1,
h2,
h3 {
  font-weight: 300;
  margin-bottom: 1em;
}
h1 {
  font-size: 20px;
}
h2 {
  font-size: 16px;
  margin-top: 1em;
}
progress,
table {
  width: 100%;
}
[type="text"],
td,
textarea,
th {
  margin-right: 5px;
  padding: 3px 6px;
}
th {
  width: 10%;
  font-weight: 500;
  text-align: right;
  white-space: nowrap;
  vertical-align: middle;
}
[type="text"],
textarea {
  width: 200px;
  padding: 5px;
  border: 1px solid darken(#eee, 20);
  border-radius: 3px;
  outline: 0;
}
[type="text"]:focus,
textarea:focus {
  border-color: darken(#eee, 30);
  box-shadow: 0 0 5px #eee;
}
textarea {
  height: 100px;
  font-family: monospace;
  vertical-align: bottom;
}
button {
  margin: 3px;
  padding: 6px 12px;
  border: 0;
  border-radius: 3px;
  outline: 0;
  background-color: #eee;
}
button:focus {
  background: darken(#eee, 5);
}
button:hover {
  background: darken(#eee, 10);
}
button:active {
  background: darken(#eee, 15);
}
[type="range"] {
  -webkit-appearance: none;
  margin: 12px/2 0;
  width: 100%;
}
[type="range"]:focus {
  outline: 0;
}
[type="range"]:focus::-webkit-slider-runnable-track {
  background: lighten(#eee, -5%);
}
[type="range"]:focus::-ms-fill-lower {
  background: #eee;
}
[type="range"]:focus::-ms-fill-upper {
  background: lighten(#eee, -5%);
}
[type="range"]::-webkit-slider-runnable-track {
  cursor: pointer;
  height: 10px;
  transition: all 0.2s ease;
  width: 100%;
  box-shadow: 0 0 0 #222, 0 0 0 lighten(#222, 5%);
  background: #eee;
  border: 0 solid #000;
  border-radius: 5px;
}
[type="range"]::-webkit-slider-thumb {
  box-shadow: 0 0 0 #111, 0 0 0 lighten(#111, 5%);
  background: #666;
  border: 0 solid #fff;
  border-radius: 12px;
  cursor: pointer;
  height: 12px;
  width: 12px;
  -webkit-appearance: none;
  margin-top: ((0 * 2 + 10px)/2) - (12px/2);
}
[type="range"]::-moz-range-track {
  cursor: pointer;
  height: 10px;
  transition: all 0.2s ease;
  width: 100%;
  box-shadow: 0 0 0 #222, 0 0 0 lighten(#222, 5%);
  background: #eee;
  border: 0 solid #000;
  border-radius: 5px;
}
[type="range"]::-moz-range-thumb {
  box-shadow: 0 0 0 #111, 0 0 0 lighten(#111, 5%);
  background: #666;
  border: 0 solid #fff;
  border-radius: 12px;
  cursor: pointer;
  height: 12px;
  width: 12px;
}
[type="range"]::-ms-track {
  cursor: pointer;
  height: 10px;
  transition: all 0.2s ease;
  width: 100%;
  background: transparent;
  border-color: transparent;
  border-width: (12px/2) 0;
  color: transparent;
}
[type="range"]::-ms-fill-lower {
  background: darken(#eee, -5%);
}
[type="range"]::-ms-fill-lower,
[type="range"]::-ms-fill-upper {
  box-shadow: 0 0 0 #222, 0 0 0 lighten(#222, 5%);
  border: 0 solid #000;
  border-radius: 5px * 2;
}
[type="range"]::-ms-fill-upper {
  background: #eee;
}
[type="range"]::-ms-thumb {
  box-shadow: 0 0 0 #111, 0 0 0 lighten(#111, 5%);
  background: #666;
  border: 0 solid #fff;
  border-radius: 12px;
  cursor: pointer;
  height: 12px;
  width: 12px;
  margin-top: 0;
}
.app {
  margin: auto;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: 300;
  text-align: center;
}
.section {
  display: inline-block;
  max-width: 480px;
  margin: 20px;
  text-align: left;
  vertical-align: top;
}
.player-wrapper {
  /*width: 480px;*/
  height: 270px;
}
.react-player {
  margin-bottom: 10px;
  background: rgba(0, 0, 0, 0.1);
}
.faded {
  color: rgba(0, 0, 0, 0.5);
}
.footer {
  margin: 20px;
}
